import { Container, Image, Stack } from '@mantine/core';
import { PropsWithChildren } from 'react';
import emblemWhite from '~/assets/bg/emblem-white.png?url';
import { LogoAdmin } from '~/assets/logo';

const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <Container fluid px={0}>
      <Container
        fluid
        p={0}
        m={0}
        style={{
          background: `url('${emblemWhite}') no-repeat top right / auto 1280px, linear-gradient(180deg, #F4F8E7 0%, #FFF 100%)`,
        }}
      >
        <Container
          size="sm"
          pt="3%"
          h="calc(100vh - var(--app-shell-header-height))"
        >
          <Stack>
            <Image src={LogoAdmin} w={292} loading="lazy" m="auto" />
            {children}
          </Stack>
        </Container>
      </Container>
    </Container>
  );
};

export default AuthLayout;
